var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TemplateHeader',{attrs:{"prefixRoute":"default-"}},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link base-link",attrs:{"to":{ name: 'index', params: { lang: _vm.$i18n.locale } }}},[_vm._v(_vm._s(_vm.$t("home-page")))])],1),_c('li',{staticClass:"nav-item"},[_c('b-dropdown',{staticClass:"custom-dropdown",attrs:{"text":_vm.$t('network')}},_vm._l((_vm.networkItems),function(item,i){return _c('b-dropdown-item',{key:i,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":_vm.getRouteLocale(item.route)}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])],1)}),1)],1),_c('li',{staticClass:"nav-item"},[_c('b-dropdown',{staticClass:"custom-dropdown nav-link",attrs:{"text":_vm.$t('academy'),"variant":"link"}},[_c('b-dropdown-item',[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{ name: 'academy-home' }}},[_vm._v(" "+_vm._s(_vm.$t("home-page"))+" ")])],1),_vm._l((_vm.items.filter(
                    (x) => x.role === _vm.userAcademyRole,
                )),function(item,index){return _c('b-dropdown-item',{key:index,staticClass:"nav-item px-1"},[_c('router-link',{staticClass:"dropdown-item nav-link",attrs:{"to":_vm.getRouteLocale(item.route)}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),_c('b-dropdown-divider'),_c('b-dropdown-item',{staticClass:"nav-link nav-item",staticStyle:{"cursor":"pointer"}},[_c('button',{staticClass:"btn btn-customer",staticStyle:{"background-color":"var(--pc)","color":"white"},on:{"click":function($event){return _vm.switchRole(
                            _vm.userAcademyRole === 'student'
                                ? 'instructor'
                                : 'student',
                        )}}},[_vm._v(" "+_vm._s(_vm.userAcademyRole === "student" ? _vm.$t("switch-to-instructor") : _vm.$t("switch-to-student"))+" ")])])],2)],1),_c('li',{staticClass:"nav-item"},[_c('b-dropdown',{staticClass:"nav-item custom-dropdown",attrs:{"text":_vm.$t('incubator'),"variant":"link"}},[_c('b-dropdown-item',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'incubator-home' }}},[_vm._v(" "+_vm._s(_vm.$t("home-page"))+" ")])],1),(_vm.userSubIncubator?.subscribe)?[_c('b-dropdown-item',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":_vm.getRouteLocale('incubator-suggestions')}},[_vm._v(" "+_vm._s(_vm.$t("your-incubator"))+" ")])],1),_c('b-dropdown-item',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":_vm.getRouteLocale('incubator-incubator-business')}},[_vm._v(" "+_vm._s(_vm.$t("incubator-stages"))+" ")])],1),_c('b-dropdown-item',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":_vm.getRouteLocale(
                                'incubator-your-learning-meetings',
                            )}},[_vm._v(" "+_vm._s(_vm.$t("learning-meetings"))+" ")])],1),_c('b-dropdown-item',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":_vm.getRouteLocale('incubator-your-courses')}},[_vm._v(" "+_vm._s(_vm.$t("training-courses"))+" ")])],1)]:_vm._e()],2)],1),_c('li',{staticClass:"nav-item"},[_c('b-dropdown',{staticClass:"custom-dropdown nav-item",attrs:{"text":_vm.$t('service-providers'),"variant":"link"}},[_c('b-dropdown-item',{staticClass:"nav-link nav-item",staticStyle:{"cursor":"pointer"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'service-provider-home' }}},[_vm._v(" "+_vm._s(_vm.$t("home-page"))+" ")])],1),_vm._l((_vm.itemsServiceProvider.filter(
                    (x) => x.provider === _vm.userIsRoleProvider,
                )),function(item,i){return _c('b-dropdown-item',{key:i,staticClass:"nav-item px-1"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":_vm.getRouteLocale(item.route)}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),_c('b-dropdown-item',[_c('button',{staticClass:"btn btn-customer",staticStyle:{"background-color":"var(--pc)","color":"white"},on:{"click":function($event){return _vm.switchRoleServiceProvider()}}},[_vm._v(" "+_vm._s(_vm.userIsRoleProvider ? _vm.$t("switch-to-buyer") : _vm.$t("switch-to-provider"))+" ")])])],2)],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link base-link",attrs:{"to":{
                name: 'affiliate-marketing-home',
                params: { lang: _vm.$i18n.locale },
            }}},[_vm._v(" "+_vm._s(_vm.$t("Affiliate-marketing"))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link base-link",attrs:{"to":_vm.getRouteLocale('network-subscribe')}},[_vm._v(_vm._s(_vm.$t("subscribes")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link base-link",attrs:{"to":{ name: 'contact-us', params: { lang: _vm.$i18n.locale } }}},[_vm._v(_vm._s(_vm.$t("contact-us")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }