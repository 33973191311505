<template>
    <TemplateHeader prefixRoute="default-">
        <li class="nav-item">
            <router-link
                :to="{ name: 'index', params: { lang: $i18n.locale } }"
                class="nav-link base-link"
                >{{ $t("home-page") }}</router-link
            >
        </li>
        <li class="nav-item">
            <!--b-dropdown :text="$t('network')" class="custom-dropdown" v-if="!user">
               <b-dropdown-item
                    class="nav-link nav-item text-right"
                    style="cursor: pointer"
                >
                    <router-link
                        :to="{ name: 'network-home' }"
                        class="nav-link"
                    >
                     {{ $t('home-page') }}  
                    </router-link>
                </b-dropdown-item>

                <b-dropdown-item
                    class="nav-link nav-item text-right"
                    style="cursor: pointer"
                >
                    <router-link
                        :to="{ name: 'network-success-stories' }"
                        class="nav-link"
                    >
                     {{ $t('success-stories') }}  
                    </router-link>
                </b-dropdown-item>

                </b-dropdown -->
            <b-dropdown :text="$t('network')" class="custom-dropdown">
                <b-dropdown-item  
                    v-for="(item, i) in networkItems"
                    :key="i"
                    class="nav-item"
                >
                    <router-link
                        :to="getRouteLocale(item.route)"
                        class="nav-link"
                    >
                        {{ $t(item.text) }}
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </li>
        <li class="nav-item">
            <!-- Check for no user -->

            <!-- Dropdown for logged-in users -->
            <b-dropdown
                :text="$t('academy')"
                class="custom-dropdown nav-link"
                variant="link"
            >
                <!-- Static Home Link -->
                <b-dropdown-item>
                    <router-link
                        :to="{ name: 'academy-home' }"
                        class="nav-link dropdown-item"
                    >
                        {{ $t("home-page") }}
                    </router-link>
                </b-dropdown-item>

                <!-- Dynamic Role-Based Links -->
                <b-dropdown-item
                    v-for="(item, index) in items.filter(
                        (x) => x.role === userAcademyRole,
                    )"
                    :key="index"
                    class="nav-item px-1"
                >
                    <router-link
                        :to="getRouteLocale(item.route)"
                        class="dropdown-item nav-link"
                    >
                        {{ item.text }}
                    </router-link>
                </b-dropdown-item>

                <!-- Role Switching Button -->
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item
                    class="nav-link nav-item"
                    style="cursor: pointer"
                >
                    <button
                        @click="
                            switchRole(
                                userAcademyRole === 'student'
                                    ? 'instructor'
                                    : 'student',
                            )
                        "
                        class="btn btn-customer"
                        style="background-color: var(--pc); color: white"
                    >
                        {{
                            userAcademyRole === "student"
                                ? $t("switch-to-instructor")
                                : $t("switch-to-student")
                        }}
                    </button>
                </b-dropdown-item>
            </b-dropdown>
        </li>

        <li class="nav-item">
            <!-- Incubator Home for Guest Users -->
            <!--router-link
                v-if="!user"
                :to="{ name: 'incubator-home', params: { lang: $i18n.locale } }"
                class="nav-link"
            >
                {{ $t("incubator") }}
            </router-link -->

            <!-- Dropdown Menu for Authenticated Users -->
            <b-dropdown
                class="nav-item custom-dropdown"
                :text="$t('incubator')"
                variant="link"
            >
                <!-- Home Link -->
                <b-dropdown-item   class="nav-item">
                    <router-link
                        :to="{ name: 'incubator-home' }"
                        class="nav-link"
                    >
                        {{ $t("home-page") }}
                    </router-link>
                </b-dropdown-item>

                <!-- Conditional Links Based on Subscription -->
                <template v-if="userSubIncubator?.subscribe">
                    <b-dropdown-item   class="nav-item">
                        <router-link
                            :to="getRouteLocale('incubator-suggestions')"
                            class="nav-link"
                        >
                            {{ $t("your-incubator") }}
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item   class="nav-item">
                        <router-link
                            :to="getRouteLocale('incubator-incubator-business')"
                            class="nav-link"
                        >
                            {{ $t("incubator-stages") }}
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item   class="nav-item">
                        <router-link
                            :to="
                                getRouteLocale(
                                    'incubator-your-learning-meetings',
                                )
                            "
                            class="nav-link"
                        >
                            {{ $t("learning-meetings") }}
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item   class="nav-item">
                        <router-link
                            :to="getRouteLocale('incubator-your-courses')"
                            class="nav-link "
                        >
                            {{ $t("training-courses") }}
                        </router-link>
                    </b-dropdown-item>
                </template>
            </b-dropdown>
        </li>

        <li class="nav-item">
            <!-- Service Provider Home for Guest Users -->
            <!--router-link
                v-if="!user"
                :to="{
                    name: 'service-provider-home',
                    params: { lang: $i18n.locale },
                }"
                class="nav-link"
            >
                {{ $t("services-provider") }}
            </router-link -->

            <!-- Dropdown Menu for Authenticated Users -->
            <b-dropdown
                class="custom-dropdown nav-item"
                :text="$t('service-providers')"
                variant="link"
            >
                <!-- Home Link -->
                <b-dropdown-item  class="nav-link nav-item"
                    style="cursor: pointer">
                    <router-link
                        :to="{ name: 'service-provider-home' }"
                        class="nav-link"
                    >
                        {{ $t("home-page") }}
                    </router-link>
                </b-dropdown-item>

                <!-- Filtered Dynamic Links -->
                <b-dropdown-item
                    v-for="(item, i) in itemsServiceProvider.filter(
                        (x) => x.provider === userIsRoleProvider,
                    )"
                    :key="i"
                    class="nav-item px-1"
                >
                    <router-link
                        :to="getRouteLocale(item.route)"
                        class="nav-link"
                    >
                        {{ item.text }}
                    </router-link>
                </b-dropdown-item>

                <!-- Switch Role Button -->
                <b-dropdown-item>
                    <button
                        @click="switchRoleServiceProvider()"
                        class="btn btn-customer"
                        style="background-color: var(--pc); color: white"
                    >
                        {{
                            userIsRoleProvider
                                ? $t("switch-to-buyer")
                                : $t("switch-to-provider")
                        }}
                    </button>
                </b-dropdown-item>
            </b-dropdown>
        </li>

        <li class="nav-item ">
            <!-- Affiliate Marketing Link -->
            <router-link
                :to="{
                    name: 'affiliate-marketing-home',
                    params: { lang: $i18n.locale },
                }"
                class="nav-link base-link"
            >
                {{ $t("Affiliate-marketing") }}
            </router-link>
        </li>
        
        <li class="nav-item ">
            <router-link
                :to="getRouteLocale('network-subscribe')"
                class="nav-link base-link"
                >{{ $t("subscribes") }}</router-link
            >
        </li>
        <li class="nav-item">
            <router-link
                :to="{ name: 'contact-us', params: { lang: $i18n.locale } }"
                class="nav-link base-link"
                >{{ $t("contact-us") }}</router-link>
        </li>

        <!-- <li class="nav-item extra-nav-item-drop">
            <b-dropdown class="custom-dropdown">
                <b-icon-view-list></b-icon-view-list>
                <b-dropdown-item
                    v-for="(item, i) in extraLinks"
                    :key="i"
                    class="nav-item"
                >
                    <router-link
                        :to="getRouteLocale(item.route)"
                        class="nav-link"
                    >
                        {{ $t(item.text) }}
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </li> -->
        <!-- <SearchInput /> -->
    </TemplateHeader>
</template>

<script>
import TemplateHeader from "../tamplate/header/index.vue";
// import SearchInput from "../tamplate/header/search.vue";

import { BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
    name: "default-header",
    data: (vm) => {
        return {
            networkItems: [
                { route: "network-home", text: "home-page" },
                { route: "network-exhibitions", text: "Exhibitions" },
                { route: "network-offers", text: "Offers" },
                {route : "network-success-stories" , text : "success-stories"},
                { route: "network-company", text: "the-companies" },
                { route: "network-models", text: "Models" },
                { route: "network-blogs", text: "Blogs" },
                { route: "network-member-network", text: "members" },
                { route: "consulting-home", text: "consulting" },
                { route: "media-magazine", text: "media_pages.riadiat_magazine" },
                { route: "media-podcast", text: "podcast" },
                { route: "network-learning-meetings", text: "meetings" }
            ],
            extraLinks: [
                {
                    route: "affiliate-marketing-home",
                    text: "Affiliate-marketing",
                },
                { route: "network-subscribe", text: "subscribes" },
                { route: "contact-us", text: "contact-us" },
            ],
            showExhibition: false,
            showModels: false,
            showOffers: false,
            items: [
                /**instructor */
                {
                    route: "academy-instructor-your-courses",
                    text: vm.$t("your-courses"),
                    role: "instructor",
                },
                {
                    route: "academy-instructor-my-meetings",
                    text: vm.$t("your-meetings"),
                    role: "instructor",
                },
                {
                    route: "academy-blogs",
                    text: vm.$t("academy-blog"),
                    role: "instructor",
                },
                {
                    route: "academy-learning-meetings",
                    text: vm.$t("academy-meetings"),
                    role: "instructor",
                },
                // { route: 'academy-contact-us', text: vm.$t('contact-us'), role: 'instructor' },
                /**student */
                {
                    route: "academy-courses",
                    text: vm.$t("academy-courses"),
                    role: "student",
                },
                {
                    route: "academy-learning-meetings",
                    text: vm.$t("academy-meetings"),
                    role: "student",
                },
                {
                    route: "academy-your-courses",
                    text: vm.$t("your-courses"),
                    role: "student",
                },
                {
                    route: "academy-your-learning-meetings",
                    text: vm.$t("your-learning-meetings"),
                    role: "student",
                },
            ],
            itemsServiceProvider: [
                /**provider */
                {
                    route: "service-provider-my-ready-services",
                    text: "متابعة خدماتى",
                    provider: true,
                },
                {
                    route: "service-provider-proposals",
                    text: "اعثر على عمل",
                    provider: true,
                },
                {
                    route: "service-provider-ready-services",
                    text: vm.$t("service"),
                    provider: false,
                },
                {
                    route: "service-provider-providers",
                    text: vm.$t("services-provider"),
                    provider: false,
                },
                {
                    route: "service-provider-contact-us",
                    text: vm.$t("contact-us"),
                    provider: true,
                },
                {
                    route: "service-provider-contact-us",
                    text: vm.$t("contact-us"),
                    provider: false,
                },
            ],
        };
    },
    methods: {
        switchRole(newRole) {
            this.switchRoleAcademy(newRole);
        },
        checkSubscriptionOptions() {
            if (!this.user) return;
            if (!this.user.subscription_options) return;
            for (
                let index = 0;
                index < this.user.subscription_options.length;
                index++
            ) {
                const element = this.user.subscription_options[index];
                if (element.key == "show_exhibitions") {
                    this.showExhibition = true;
                } else if (element.key == "models") {
                    this.showModels = true;
                } else if (element.key == "show_offers") {
                    this.showOffers = true;
                }
            }
        },
        switchRoleServiceProvider() {
            this.$store.commit("changeRole");
            if (this.$store.state.isProviderRole) {
                localStorage.setItem(
                    "providerOrclient",
                    JSON.stringify("provider"),
                );
            } else if (!this.$store.state.isProviderRole) {
                localStorage.setItem(
                    "providerOrclient",
                    JSON.stringify("client"),
                );
            }
        },
    },
    computed: {
        userIsRoleProvider() {
            return this.$store.state.isProviderRole;
        },
    },
    mounted() {
        this.checkSubscriptionOptions();
    },
    components: {
        BDropdown,
        BDropdownItem,
        TemplateHeader,
    //    SearchInput,
    },
};
</script>

<style>
.custom-dropdown .btn {
    background-color: transparent;
    /* Set the background color to transparent */
    border: 1px solid #ced4da;
    /* Add a border to maintain button structure */
    color: #495057;
    /* Set text color to your desired color */
    border: none;
}

.custom-dropdown .btn:hover,
.custom-dropdown .btn:focus {
    background-color: transparent;
    /* Set the background color for hover/focus state */
    border: 1px solid #80bdff;
    /* Adjust border color for hover/focus state */
}
/* search */
.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
   
}

form .input-container input {
    border: none !important;
    outline: none;
    flex: 1;
    font-size: 16px;
    background: #fff !important;
}

.input-container .icon {
    margin-right: 10px;
    font-size: 18px;
    color: #888;
}

.input-container:focus-within {
    border-color: rgba(44, 183, 179, 1);
}

.extra-nav-item-drop {
    display: none;
}

nav-item {
    display: inline-block; /* Make items inline */
    font-size: 16px; /* Adjust the font size */
    margin-right: 15px; /* Add space between items */
}

.base-link {
    color: #495057 !important; /* Ensure the text is white */
    font-size: 16px; /* Set font size for nav links */
}
/* Adjust the dropdown button and menu to match the smaller font size */
.custom-dropdown .btn {
    font-size: 16px; /* Adjust the font size of the dropdown button */
}


@media screen and (max-width: 1600px) and (min-width: 1200px) {
    .extra-nav-item {
        display: none;
    }
    .extra-nav-item-drop {
        display: block;
    }
}
</style>
